import '../scss/styles.scss'
import $ from 'jquery';
window.$ = $;

(() => {
    'use strict'
    const forms = document.querySelectorAll('.needs-validation')

    Array.from(forms).forEach(form => {
      form.addEventListener('submit', event => {
        if (!form.checkValidity()) {
          event.preventDefault()
          event.stopPropagation()
        }else{

          let formData = {
            member:$("#member").val(),
            party: $('input[name=party]:radio:checked').val()
          };
    
          $.ajax({
            type: "POST",
            url: "/bin/send.php",
            data: formData,
            dataType: "json",
            encode: true,
          }).done(function (data) {
            $('.alert').remove();
            $('.fixer').remove();
            if (!data.success) {
              $('#send_btn').after(function() {
                  return '<div class="clearfix fixer pb-3"></div><div class="alert alert-danger" role="alert">' + data.message + '</div>';
              });
            } else {
              $('#send_btn').hide();
              
              $('#send_btn').after(function() {
                  return '<div class="alert alert-success" role="alert">' + data.message + '</div>';
              });
            }              
          });
      
          event.preventDefault();
          event.stopPropagation();
        }
        form.classList.add('was-validated')
      }, false)
    })
  })()